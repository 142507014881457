<template>
  <div class="category-desktop">
    <div v-if="displayedCategories.length" class="displayed-category-wrapper">
      <vod-categories-item
        v-for="category in displayedCategories"
        :key="category.id"
        :category-info="category"
        @category-selected="selectCategory"
        class="short-category"
        :selectedCategory="selectedCategory"
        subtree-variant="subtree_bottom"
        :class="{
          selected: Number(category.id) === Number(selectedCategory),
        }"
      />
    </div>
    <div v-if="hidedCategories.length" class="more-wrapper">
      <button @click.stop.prevent="toggleMore" class="more-button btn">
        {{ $t('more') }}...
      </button>
      <div
        v-if="displayMore"
        class="more-list"
        v-click-outside="displayMore && toggleMore"
      >
        <vod-categories-item
          v-for="category in hidedCategories"
          :key="category.id"
          :category-info="category"
          @category-selected="selectCategory"
          class="short-category"
          subtree-variant="subtree_right"
          :selectedCategory="selectedCategory"
          :class="{
            selected: Number(category.id) === Number(selectedCategory),
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VodCategoriesItem from './VodCategoriesItem';
export default {
  name: 'VodDesktopCategories',
  components: { VodCategoriesItem },
  props: {
    categoriesList: {
      type: Array,
    },
    selectedCategory: {
      type: Boolean,
    },
    screen_limit: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      displayMore: false,
    };
  },
  computed: {
    displayedCategories() {
      return this.categoriesList.slice(0, this.screen_limit);
    },
    hidedCategories() {
      return this.categoriesList.slice(this.screen_limit);
    },
  },
  methods: {
    selectCategory(category) {
      this.$emit('category-selected', category);
      this.displayMore && this.toggleMore();
    },
    toggleMore() {
      this.displayMore = !this.displayMore;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/colors';
.displayed-category-wrapper {
  background: $text-tertiary-dark;
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid $text-tertiary-dark;
  gap: 1px;

  .short-category {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
.more-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  border-radius: 0.5rem;
  border: 1px solid $text-tertiary-dark;
  min-width: 10rem;
  width: max-content;
  gap: 1px;
  z-index: 10;
  .short-category {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

.more-wrapper {
  position: relative;
}

.more-button {
  padding: 1rem;
  border: 1px solid $text-tertiary-dark;
  border-radius: 0.5rem;
  background: $secondary-background;
}

.short-category {
  max-width: 15rem;
}

.category-desktop {
  display: flex;
  gap: 1rem;
}
</style>
