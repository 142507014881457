<template>
	<div
		class="category-item"
		@click.stop="selectCurrentCategory(categoryInfo)"
		@mouseenter.stop="openSubtree"
		@mouseleave.stop="closeSubtree"
		:title="categoryInfo.title"
	>
		<span>{{categoryInfo.title}}</span>
		<div
			v-if="categoryInfo.subtree && subCategoryTreeOpened"
			class="subtree"
			:class="{
				[subtreeVariant]: subtreeVariant
			}"
		>
			<vod-categories-item
				v-for="category in categoryInfo.subtree"
				:key="category.id"
				:category-info=category
				@category-selected="selectCurrentCategory"
				class="short-category"
				subtree-variant="subtree_right"
				:selectedCategory="selectedCategory"
				:class="{
                          selected: Number(category.id) === Number(selectedCategory)
                        }"
			/>
		</div>
	</div>
</template>

<script>
  export default {
    name: "VodCategoriesItem",
    data() {
      return {
        subCategoryTreeOpened: false,
        arrowIcon: "",
      }
    },
    props: {
      categoryInfo: {
        type: Object,
      },
      subtreeVariant: {
        type: String,
        default: 'subtree_bottom'
      },
      selectedCategory: {
        type: [Number, String]
      },
    },
    methods: {
      openSubtree() {
        this.subCategoryTreeOpened = true
      },
      closeSubtree() {
        this.subCategoryTreeOpened = false
      },
      selectCurrentCategory(info) {
        this.$emit('category-selected', info)
        this.closeSubtree()
      },
    }
  }
</script>

<style lang="scss" scoped>
	@import "../../../assets/css/colors";

	.category-item {
		display: flex;
		background: $secondary-background;
		padding: 1rem;
		line-height: 1.5;
		cursor: pointer;
		width: 100%;
		color: $link;
		flex-grow: 1;
		position: relative;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.subtree {
		position: absolute;
		z-index: 10;
		min-width: 12rem;
		border-radius: 1rem;
		border: 1px solid $text-tertiary-dark;
		background: $text-tertiary-dark;
		display: flex;
		flex-direction: column;
		gap: 1px;
		.category-item {
			border-radius: 0;
			&:first-child {
				border-top-left-radius: .5rem;
				border-top-right-radius: .5rem;
			}
			&:last-child{
				border-bottom-right-radius: .5rem;
				border-bottom-left-radius: .5rem;
			}
		}
	}

	.subtree_bottom{
		top: 100%;
		left: 0;
	}
	.subtree_right{
		top: 0;
		left: 100%;
	}

	.selected {
		background: $accent-warning;
		> span{
			font-weight: 600;
		}
	}
</style>